<template>
  <v-card
    v-if="voucherFilename"
    max-width="1000"
    class="pa-5 mx-auto"
  >
    <v-img :src="require(`@/assets/images/${voucherFilename}`)">
      <v-overlay
        v-if="voucherCode"
        color="transparent"
        class="voucher-transparent-overlay"
      >
        <v-card
          color="transparent"
          class="voucher-code-top"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherCode }}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          color="transparent"
          class="voucher-expired-at-top"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherExpireDate }}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          color="transparent"
          class="voucher-expired-at-side"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherExpireDate }}
            </div>
          </v-card-text>
        </v-card>
        <v-card
          color="transparent"
          class="voucher-code-side"
        >
          <v-card-text>
            <div class="voucher-text">
              {{ voucherCode }}
            </div>
          </v-card-text>
        </v-card>
      </v-overlay>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    voucherCode: {
      type: String,
      default: '',
    },
  },
  name: 'voucher-preview',
  data: () => ({
    voucherExpireDate: null,
    voucherFilename: null,
  }),
  mounted() {
    this.fetchVoucher()
  },
  methods: {
    fetchVoucher() {
      this.$store
        .dispatch('fetchVoucher', { preview_token: this.$route.params.preview_token })
        .then(response => {
          this.voucherExpireDate = response.data.data.expired_at
          this.voucherFilename = `voucher_${Math.round(response.data.data.amount)}.jpg`
        })
        .catch((error) => {
          this.$router.push({name: 'Home', params: { message: error.response.data.message }})
        })
    },
  },
}
</script>

<style>
.voucher-transparent-overlay {
  position: absolute;
  justify-content: normal;
  align-items: normal;
}
.voucher-text {
  color: black !important;
  font-size: 19px;
  font-weight: bold;
}
.voucher-code-top {
  box-shadow: none !important;
  left: 135px;
  top: -4px;
}
.voucher-expired-at-top {
  box-shadow: none !important;
  left: 240px;
  top: -34px;
}
.voucher-expired-at-side {
  box-shadow: none !important;
  left: -6px;
  top: -22px;
  transform: rotate(-90deg);
}
.voucher-code-side {
  box-shadow: none !important;
  left: -6px;
  top: 57px;
  transform: rotate(-90deg);
}
@media (max-width: 1775px) {
  .voucher-code-top {
    left: 115px;
    top: -4px;
  }
  .voucher-expired-at-top {
    left: 205px;
    top: -35px;
  }
  .voucher-expired-at-side {
    left: -6px;
    top: -35px;
  }
  .voucher-code-side {
    left: -6px;
    top: 30px;
  }
}
@media (max-width: 1610px) {
  .voucher-text {
    font-size: 17px;
  }
  .voucher-code-top {
    left: 105px;
    top: -8px;
  }
  .voucher-expired-at-top {
    left: 170px;
    top: -45px;
  }
  .voucher-expired-at-side {
    left: -12px;
    top: -45px;
  }
  .voucher-code-side {
    left: -12px;
    top: 5px;
  }
}
@media (max-width: 1400px) {
  .voucher-text {
    font-size: 15px;
  }
  .voucher-code-top {
    left: 82px;
  }
  .voucher-expired-at-top {
    left: 155px;
    top: -50px;
  }
  .voucher-expired-at-side {
    top: -60px;
  }
  .voucher-code-side {
    top: -15px;
  }
}
@media (max-width: 1263px) {
  .voucher-text {
    font-size: 18px;
  }
  .voucher-code-top {
    left: 145px;
    top: -2px;
  }
  .voucher-expired-at-top {
    left: 240px;
    top: -32px;
  }
  .voucher-expired-at-side {
    left: -4px;
    top: -30px;
  }
  .voucher-code-side {
    left: -4px;
    top: 55px;
  }
}
@media (max-width: 768px) {
  .voucher-text {
    font-size: 15px;
  }
  .voucher-code-top {
    left: 100px;
    top: -10px;
  }
  .voucher-expired-at-top {
    left: 175px;
    top: -45px;
  }
  .voucher-expired-at-side {
    left: -12px;
    top: -50px;
  }
  .voucher-code-side {
    left: -12px;
    top: 0px;
  }
}
@media (max-width: 682px) {
  .voucher-text {
    font-size: 14px;
  }
  .voucher-code-top {
    left: 85px;
  }
  .voucher-expired-at-top {
    left: 155px;
    top: -48px;
  }
  .voucher-expired-at-side {
    top: -50px;
  }
  .voucher-code-side {
    top: -20px;
  }
}
@media (max-width: 480px) {
  .voucher-text {
    font-size: 8px;
  }
  .voucher-code-top {
    left: 58px;
    top: -16px;
  }
  .voucher-expired-at-top {
    left: 100px;
    top: -60px;
  }
  .voucher-expired-at-side {
    left: -10px;
    top: -80px;
  }
  .voucher-code-side {
    left: -10px;
    top: -70px;
  }
}
@media (max-width: 480px) {
  .voucher-text {
    font-size: 8px;
  }
  .voucher-code-top {
    left: 58px;
    top: -16px;
  }
  .voucher-expired-at-top {
    left: 100px;
    top: -60px;
  }
  .voucher-expired-at-side {
    left: -10px;
    top: -80px;
  }
  .voucher-code-side {
    left: -10px;
    top: -70px;
  }
}
@media (max-width: 418px) {
  .voucher-text {
    font-size: 7px;
  }
  .voucher-code-top {
    left: 48px;
    top: -16px;
  }
  .voucher-expired-at-top {
    left: 82px;
    top: -62px;
  }
  .voucher-expired-at-side {
    left: -10px;
    top: -90px;
  }
  .voucher-code-side {
    left: -10px;
    top: -85px;
  }
}
</style>
